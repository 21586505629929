

.App {
  text-align: center;
}

.errorText {
  color: #ff0000;
  font-size: 12px;
  font-weight: 500;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
