@font-face {
  font-family: Poppins;
  src: url('../public/Poppins-Medium.ttf');
}

body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: Poppins !important;
}

s html {
  scroll-behavior: smooth;
}