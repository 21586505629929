table {
    border-collapse: collapse;
    width: 100%;
    /* font-family: 'Poppins'; */
}

thead th {
    padding: 8px;
    background-color: white;
    color: rgba(149, 149, 149, 1)
}

tbody th, td {
    padding: 8px;
    border-bottom: 15px solid transparent;
    background-color: #f8f8f8;
    /* color: white; */
    background-clip: padding-box;

}

thead, tbody {
    /* display: block; */
    width: '100%';
    row-gap: 10px;


}

thead {
    background-color: #f2f2f2;
    /* optional: add a background color to the header */
    height: '80px';
    text-align: left;

}

tbody {
    max-height: 200px;
    overflow-y: auto;
    /* border: 1px solid red */
}

.tableBody::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.tableBody {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
th {
    background-color: #f2f2f2;
    /* optional: add a background color to the header */
    position: sticky;
    top: 0;
    z-index: 1;

}

tr {
    /* border: 1px solid #ddd; */
    border-radius: 20px;
    /* margin-bottom: 10px; */
}